import { RootObject } from '@next-app/interface/Common';
import findComponent from '@next-app/utils/find-component';

interface FooterProps {
  contentItem: RootObject;
  userAgent: string;
}

export default function Footer(props: FooterProps) {
  const item = props?.contentItem?.footer;
  const Component = findComponent(item);

  if (!Component) {
    return null;
  }

  return (
    <div
      id="global-footer"
      className={`global-footer`}
      role="contentinfo"
      {...{ s_regionid: 'Global Footer - Main' }}
    >
      <Component contentItem={item} />
    </div>
  );
}
