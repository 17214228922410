import findComponent from '@next-app/utils/find-component';
import getUniqueKey from '@next-app/utils/get-unique-key';
import { RootObject } from '@next-app/interface/Common';
import { HeaderBlockContent } from '@next-app/interface/Header';
import HeaderTabs from './HeaderTabs';
import { HeaderNavigationContent } from '@next-app/interface/Navigation';

import classes from './header.module.scss';

import SkeletonUI from '@next-app/components/Skeleton/Skeleton';
import HeaderEyebrow from '../PageContent/HeaderEyebrow/HeaderEyebrow';

interface HeaderProps {
  contentItem: RootObject;
}

type DynamicComponentType = React.ComponentType<{
  contentItem: HeaderBlockContent;
}>;

export default function Header(props: HeaderProps) {
  const ComponentList =
    props.contentItem?.headerBlock?.contents[0]?.content.map(
      (item: HeaderBlockContent, index: number) => {
        const DynamicComponent = findComponent(item) as DynamicComponentType;
        return (
          <DynamicComponent
            contentItem={item}
            key={getUniqueKey(index, item.name)}
          />
        );
      },
    );

  const NavComponentList = props.contentItem?.navigation?.contents.map(
    (item: HeaderNavigationContent, index: number) => {
      const DynamicComponent = findComponent(item) as DynamicComponentType;
      return (
        <DynamicComponent
          contentItem={item}
          key={getUniqueKey(index, item.name)}
        />
      );
    },
  );

  const HeaderEye = props.contentItem?.headerBlock?.contents[0]?.content.map(
    (item: HeaderBlockContent, index) => {
      if (item.headerEyebrow) {
        const messages = item.headerEyebrow[0]?.contents[0]?.messages;
        if (messages) {
          const SkeletonComponent = (
            <SkeletonUI
              key={index}
              width={320}
              height={20}
              containerClass={classes['eyebrowSkeleton']}
            />
          );
          return (
            <HeaderEyebrow
              key={index}
              contentItem={messages}
              SkeletonComponent={SkeletonComponent}
            />
          );
        }
      }
    },
  );

  return (
    <div id="header-component">
      <div id="header-wrapper" {...{ s_regionid: 'Header - Main' }}>
        <div id="sticky-header" className="f-enabled headerWrapper">
          {/* if not furniture tab */}
          <div id="ll-xs-eyebrow" className={classes['ll-xs-eyebrow']}>
            Show all header eyebrows
          </div>
          {/* if is furniture tab and not checkout header */}
          <div className="eyebrowSection hideHeaderElem">
            <div className="container">
              <div
                className={`d-flex flex-column flex-md-row justify-content-between align-items-center ${classes.eyebrowSectionRow}`}
              >
                <HeaderTabs />
                <div className="order-md-last order-first text-end">
                  {/* A <p> tag can not be a parent for proper html structure, making this a <p> tag throws an error */}
                  <div className={classes.headerEye}>{HeaderEye}</div>
                </div>
              </div>
            </div>
          </div>
          {/* not display Temp header ---> Actual header starts */}
          <div>{ComponentList}</div>
          {NavComponentList}
        </div>
      </div>
    </div>
  );
}
