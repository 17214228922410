import SharedLayoutComponent from '../../sharedlayout';
import Link from 'next/link';
import ErrorDTMEvent from '../ErrorDTMEvent/ErrorDTMEvent';
import classes from './_oops-error.module.scss';
import OopsErrorRedirection from './OopsErrorRedirection';
import InjectDynamicYield from '@next-app/script/InjectDynamicYield';

interface ErrorProps {
  error: Error & { digest?: string };
}

const OopsError = ({ error }: ErrorProps) => {
  return (
    <SharedLayoutComponent>
      <InjectDynamicYield page={'OTHER'} data={['oops-page']} />
      <div className="container" style={{ maxWidth: '1140px' }}>
        <div
          className="d-flex justify-content-center mt-3"
          data-att="error-page"
        >
          <div className="text-center">
            <p
              style={{
                letterSpacing: '-.015em',
                color: '#181818',
                fontSize: '28px',
                fontWeight: 'bold',
                lineHeight: '30px',
                marginBottom: '25px',
                marginTop: '72px',
              }}
            >
              Oops! This Page Is on a Field Trip.
            </p>
            <p
              style={{
                letterSpacing: '-0.015em',
                color: '#181818',
                fontSize: '18px',
                lineHeight: '24px',
                marginBottom: '47px',
              }}
            >
              Click the links below to continue shopping.
            </p>
            <OopsErrorRedirection />
          </div>
        </div>

        <p
          className="ps-3"
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
            marginTop: '50px',
          }}
        >
          Explore Categories
        </p>
        <div className="container mt-4 mb-4">
          <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between gap-2">
            <div
              className={`${classes['pineGreen']} ${classes['category-box']}`}
            >
              <Link
                href="/products/language/N/951228441"
                className="text-white"
              >
                Language
              </Link>
            </div>
            <div
              className={`${classes['darkPurple']} ${classes['category-box']}`}
            >
              <Link href="/products/math/N/598473326" className="text-white">
                Math
              </Link>
            </div>
            <div
              className={`${classes['darkOrange']} ${classes['category-box']}`}
            >
              <Link
                href="/products/blocks-manipulatives/N/1784882785"
                className={`text-white ${classes['vertical-align']}`}
              >
                Blocks & Manipulatives
              </Link>
            </div>
            <div
              className={`${classes['blueLagoon']} ${classes['category-box']}`}
            >
              <Link
                href="/products/dramatic-play/N/1282659029"
                className="text-white"
              >
                Dramatic Play
              </Link>
            </div>
            <div
              className={`${classes['blueNavyNew']} ${classes['category-box']}`}
            >
              <Link
                href="/products/arts-crafts/N/4206337237"
                className="text-white"
              >
                Arts & Crafts
              </Link>
            </div>
            <div
              className={`${classes['darkPink']} ${classes['category-box']}`}
            >
              <Link
                href="/products/teaching-resources/N/419068704"
                className={`text-white ${classes['vertical-align']}`}
              >
                Teaching Resources
              </Link>
            </div>
          </div>
        </div>

        <div id="op_carousel"></div>
      </div>

      <ErrorDTMEvent />
    </SharedLayoutComponent>
  );
};

export default OopsError;
