'use client';

import { fsSetProperties } from '@next-app/script/FullStoryScript';
import { isPdpPage, isPlpPage, isHomePage } from '@next-app/utils/common-utils';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';
import classes from './_oops-error.module.scss';

const OopsErrorRedirection = () => {
  const pathname = usePathname();
  const router = useRouter();
  const page = isPdpPage(pathname)
    ? 'pdp'
    : isPlpPage(pathname)
      ? 'plp'
      : isHomePage(pathname)
        ? 'home'
        : 'Page Not Found';

  useEffect(() => {
    if (global?.window && global?.window?.FS) {
      fsSetProperties({ oopsErrorOnPage: page });
    }
  }, [global?.window]);
  return (
    <div className={`${classes['icon-container']}`}>
      <a href="/">
        <i className={`ls-icon mb-3 ${classes['icn-home-solid']}`}></i>
        <span>Home</span>
      </a>
      <a onClick={() => router.refresh()}>
        <i className={`ls-icon mb-3 ${classes['icn-refresh']}`}></i>
        <span>Refresh</span>
      </a>
      <a onClick={() => router.back()}>
        <i className={`ls-icon mb-3 ${classes['icn-go-back']}`}></i>
        <span>Go Back</span>
      </a>
    </div>
  );
};

export default OopsErrorRedirection;
