import { Header, Footer } from '@shared/components';
import InitialData from './utils/InitialData';

interface SharedLayoutProps {
  children: React.ReactNode;
  Endeca_user_segments?: string;
  Endeca_date?: string;
}

const SharedLayoutComponent: React.FC<SharedLayoutProps> = async ({
  children,
  Endeca_user_segments,
  Endeca_date,
}) => {
  const { headerFooter }: any = await InitialData.getJson({
    Endeca_user_segments,
    Endeca_date,
  });

  return (
    <div id="wrapper" className="new-header-enabled b2c-user">
      <div className="mp-pusher" id="mp-pusher">
        {/* Header */}
        <Header contentItem={headerFooter} />
        {/* Main content area */}
        <main id="main" className="main-page" tabIndex={-1} role="main">
          <div className="modal-backdrop opaque"></div>
          <div className="main-container">{children}</div>
        </main>

        {/* Footer */}
        <Footer contentItem={headerFooter} userAgent={''} />
      </div>
    </div>
  );
};

export default SharedLayoutComponent;
